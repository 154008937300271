@import '~bootstrap/scss/bootstrap';

@import './styles/button';
@import './styles/form';
@import './styles/utilities';

body {
  margin: 0;
  font-family: 'Inter', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}