.wrapper {
    display: flex;
    align-items: center;

    div {
        margin: -5px;
    }

    p {
        margin-left: 5px;
        color: #000;
    }

    &.primary p {
        color: cyan;
    }

    &.light p {
        color: cyan;
    }
}