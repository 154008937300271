header {
  .notification-bar {
    padding: 6px 0;
    background-color: #0a2540;

    p {
      font-size: 14px;
      text-align: center;
      color: #ffffff;
      margin: 0;
    }
  }

  .navbar {
    background-color: #054181;

    &__content {
      width: 100%;
      display: flex;
      justify-content: space-between;
      align-items: center;
      padding: 1em 0;

      .connect-button {

        &-container {
          position: relative;
          display: flex;
          justify-content: center;
          align-content: center;
          align-items: center;
        }

        &-spinner {
          height: 30px;
        }
      }

      img {
        height: 3rem;
      }

      @media (min-width: 992px) {
        img {
          height: 4rem !important;
        }
  
      }
      
      ul {
        margin: 0;
        

        @media (min-width: 992px) {
          display: block;
        }

        li {
          display: block;
          @media (min-width: 992px) {
            margin-left: 40px;
            display: inline-block !important;
            
          }
          

          a:not([role='button']) {
            color: #fff;
            font-weight: 600;
            font-size: 1.13rem;
            text-decoration: none;
            transition: 100ms all ease-in;

            &:hover {
              color: darken(#fff, 10%);
            }
          }
        }
      }
    }
  }
}