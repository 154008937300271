.default-button {
    border-radius: 32px;
    padding: 0.75rem 2rem;
    box-shadow: 0px 2px 7px 0px #0000003d;

    &-primary {
        font-size: 1.13rem;
        font-weight: 600;
        background-color: #2a73bf;
    }
}